import { ProfileApi } from '../../../apis';
import { List } from '../../../components';
import { PAGE_LIMIT } from '../../../constants';
import { IProfile, Order } from '../../../interfaces';
import { NewProfileModal } from '../Form';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Chip, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';

export const ProfileListPage: FC = () => {
  const [visibleNewModal, setVisibleNewModal] = useState<boolean>(false);
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [order, setOrder] = useState<Order>(Order.Desc);
  const [orderBy, setOrderBy] = useState<string>('name');
  const [profile, setProfile] = useState<IProfile | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const fetchProfiles = () => {
    setIsLoading(true);
    ProfileApi.readAll({
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT,
        sort: {
          [orderBy]: order
        }
      }
    })
      .then((res) => {
        setProfiles(res.data);
        setTotalPage(res.pagination.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: 'Name',
      render: (row) => (
        <Stack direction="row" spacing={8}>
          <AccountCircleOutlined />
          <Typography>{row.name}</Typography>
        </Stack>
      )
    },
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Projects',
      render: (row) => (
        <Stack direction="row" spacing={4}>
          {row.projects?.map(({ name }, index) => (
            <Chip key={index} label={name} />
          ))}
        </Stack>
      )
    }
  ];

  const handleOpen = () => {
    setVisibleNewModal(true);
    setProfile(null);
  };

  const handleClose = (isReload?: boolean) => {
    if (isReload) {
      fetchProfiles();
    }
    setVisibleNewModal(false);
    setProfile(null);
  };

  const handleEdit = (id) => {
    ProfileApi.read(id).then((res) => {
      setProfile(res.data);
      setVisibleNewModal(true);
    });
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    ProfileApi.remove(id)
      .then((res) => {
        fetchProfiles();
        enqueueSnackbar(res.msg, { variant: 'success' });
      })
      .catch((err) => {
        setIsLoading(false);
        enqueueSnackbar(err.msg, { variant: 'error' });
      });
  };

  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? Order.Desc : Order.Asc);
    setOrderBy(property);
  };

  const handleCopy = (id: string) => {
    console.log(id);
  };

  useEffect(() => {
    fetchProfiles();
  }, [pageNumber, order, orderBy]);

  return (
    <>
      <List
        data={profiles}
        columns={columns}
        isLoading={isLoading}
        totalPage={totalPage}
        pageNumber={pageNumber}
        onNew={handleOpen}
        onCopy={handleCopy}
        onSort={handleSort}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onPageChange={handlePageChange}
      />
      {visibleNewModal && <NewProfileModal open={visibleNewModal} profile={profile} onClose={handleClose} />}
    </>
  );
};
