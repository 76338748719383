export interface IAccount {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IGetParams {
  query?: any;
  projection?: any;
  options?: any;
  aggregate?: any;
}

export interface IRootState {
  authReducer: {
    account: IAccount;
  };
}

export enum Order {
  Asc = 'asc',
  Desc = 'desc'
}

export interface IProfile {
  id: string;
  name: string;
  email: string;
  projects: IProject[];
}

export interface ISetting {
  rootPath: string;
  sshConfigPath: string;
  interval: number;
  isSyncing: boolean;
  user: IAccount;
}

export interface IProject {
  id: string;
  name: string;
  type: string;
  repos: any[];
  profile: IProfile;
  status: boolean;
  folderPath?: string;
}
