import { ENDPOINTS } from '../constants';
import { IGetParams } from '../interfaces';
import { IRepository } from '../pages/Repository/Form';
import { Http } from '../utils';

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.REPOSITORY.BASE, params);
};

const create = (repo): Promise<any> => {
  return Http.post(ENDPOINTS.REPOSITORY.BASE, repo);
};

const deleteById = (id: string): Promise<any> => {
  return Http.delete(ENDPOINTS.REPOSITORY.ID.replace(':id', id));
};

const getById = (id: string): Promise<any> => {
  return Http.get(ENDPOINTS.REPOSITORY.ID.replace(':id', id));
};

const edit = (id: string, repo: IRepository): Promise<any> => {
  return Http.put(ENDPOINTS.REPOSITORY.ID.replace(':id', id), repo);
};

export const RepositoryAPI = {
  readAll,
  create,
  deleteById,
  getById,
  edit
};
