import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { ROUTES } from '../../../constants';
import * as S from './styles';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const NavItems = [
  {
    label: 'Dashboard',
    to: ROUTES.DASHBOARD
  },
  {
    label: 'Projects',
    to: ROUTES.PROJECT.LIST
  },
  {
    label: 'Repositories',
    to: ROUTES.REPOSITORY.LIST
  },
  {
    label: 'Profiles',
    to: ROUTES.PROFILE.LIST
  },
  {
    label: 'Monitor',
    to: ROUTES.MONITOR
  }
];

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleNavigateTo = (to) => () => {
    navigate(to);
  };

  return (
    <S.Sidebar>
      {/*@ts-ignore*/}
      <S.Brand to={ROUTES.DASHBOARD}>
        <Logo />
      </S.Brand>
      <Stack p={20}>
        {NavItems.map(({ label, to }, index) => (
          <S.NavItem key={index} active={to === pathname} onClick={handleNavigateTo(to)}>
            {label}
          </S.NavItem>
        ))}
      </Stack>
    </S.Sidebar>
  );
};

export default Sidebar;
