import { ENDPOINTS } from '../constants';
import { IGetParams } from '../interfaces';
import { Http } from '../utils';

const read = (): Promise<any> => {
  return Http.get(ENDPOINTS.SETTING.BASE);
};

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.SETTING.BASE, params);
};

const create = (setting): Promise<any> => {
  return Http.post(ENDPOINTS.SETTING.BASE, setting);
};

const update = (data): Promise<any> => {
  return Http.put(ENDPOINTS.SETTING.BASE, data);
};

const remove = (id): Promise<any> => {
  return Http.delete(ENDPOINTS.SETTING.ID.replace(':id', id));
};

export const SettingApi = {
  read,
  readAll,
  create,
  update,
  remove
};
