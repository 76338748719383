import { FC, useEffect, useState } from 'react';
import { Box, Link, Chip } from '@mui/material';
import { RepositoryAPI } from '../../../apis/repository';
import { List } from '../../../components';
import { RepositoryModal } from '../Form';
import { Order } from '../../../interfaces';
import { PAGE_LIMIT } from '../../../constants';

export const RepositoryListPage: FC = () => {
  const [visibleNewModal, setVisibleNewModal] = useState<boolean>(false);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepository, setSelectedRepository] = useState<string | null>(null);
  const [order, setOrder] = useState<Order>(Order.Desc);
  const [orderBy, setOrderBy] = useState<string>('name');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalPage, setTotalPage] = useState<number>();

  useEffect(() => {
    fetch();
  }, [pageNumber, order, orderBy]);

  const fetch = () => {
    setIsLoading(true);
    RepositoryAPI.readAll({
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT,
        sort: {
          [orderBy]: order
        }
      }
    }).then((res) => {
      setRepositories(res.data);
      setTotalPage(res.pagination.total);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleOpen = () => {
    setVisibleNewModal(true);
  };

  const handleEditOpen = (id: string) => {
    setSelectedRepository(id);
    setVisibleNewModal(true);
  };

  const handleClose = () => {
    setVisibleNewModal(false);
    setSelectedRepository(null);
  };

  const handleDelete = (id: string) => {
    RepositoryAPI.deleteById(id).then(() => {
      fetch();
    });
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? Order.Desc : Order.Asc);
    setOrderBy(property);
  };

  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  const columns = [
    {
      title: 'Name',
      field: 'name'
    },
    {
      title: 'Project',
      render: (row) => row.project?.name
    },
    {
      title: 'Origins',
      render: (row) =>
        row.origins?.map((origin, index) => (
          <Box display="flex" flexWrap="wrap" key={index}>
            <Link href={origin.url}>{origin.name}</Link>
          </Box>
        ))
    },
    {
      title: 'Branches',
      render: (row) => row.branches.map((branch, index) => <Chip key={index} label={branch} sx={{ m: 2 }} />)
    },
    {
      title: 'Webhook'
    }
  ];

  return (
    <>
      <List
        data={repositories}
        columns={columns}
        isLoading={isLoading}
        totalPage={totalPage}
        pageNumber={pageNumber}
        onSort={handleSort}
        onPageChange={handlePageChange}
        onNew={handleOpen}
        onDelete={handleDelete}
        onEdit={handleEditOpen}
      />
      {visibleNewModal && <RepositoryModal selectedRepository={selectedRepository} open={visibleNewModal} onClose={handleClose} onRefresh={fetch} />}
    </>
  );
};
