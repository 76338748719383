import { Paper, Table } from '@mui/material';
import styled from 'styled-components';

export const Content = styled(Table)`
  && {
    .MuiTableRow-root {
      .MuiTableCell-root {
        &:first-child {
          ${(props) => props.theme.typography.subtitle1};
          font-weight: 600;
        }
      }
    }
  }
`;

export const BranchPaper = styled(Paper)`
  && {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
    padding: 4px;
    box-shadow: none;
    min-height: 42px;

    .MuiListItem-root {
      padding: 2px 4px;
      width: auto;
    }
  }
`;
