import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ROUTES } from '../../constants';
import * as S from './styles';
import { FC } from 'react';

export const AuthLayout: FC = ({ children }) => {
  return (
    <S.Layout>
      <S.Content>
        <S.Logo to={ROUTES.AUTH.SIGN_IN}>
          <Logo />
        </S.Logo>
        {children}
      </S.Content>
    </S.Layout>
  );
};
