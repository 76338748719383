import { Box, ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Sidebar = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 240px;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
`;

export const Brand = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
  height: 80px;

  svg {
    height: 50px;
  }
`;

export const NavItem = styled(ButtonBase)<{ active: boolean }>`
  border-radius: 4px;
  padding: ${(props) => props.theme.spacing(10, 10)};
  ${(props) => props.theme.typography.subtitle1};
  justify-content: flex-start;
  font-weight: 600;

  &:hover {
    color: ${(props) => props.theme.palette.secondary.main};
    text-shadow: 0 0 2px ${(props) => props.theme.palette.secondary.main};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.palette.secondary.main};
      text-shadow: 0 0 2px ${(props) => props.theme.palette.secondary.main};
    `}
`;
