export const ENDPOINTS = {
  AUTH: {
    REGISTER: '/auth/register',
    LOGIN: '/auth/login',
    ME: '/auth/me'
  },
  PROFILE: {
    BASE: '/profile',
    ID: '/profile/:id'
  },
  PROJECT: {
    BASE: '/project',
    ID: '/project/:id'
  },
  REPOSITORY: {
    BASE: '/repository',
    ID: '/repository/:id'
  },
  SETTING: {
    BASE: '/setting',
    ID: '/setting/:id'
  }
};
