import { AuthApi } from '../../../apis';
import { ACCESS_TOKEN_KEY, ROUTES } from '../../../constants';
import { setAccount } from '../../../store/actions';
import { Email, Google, Lock, Login } from '@mui/icons-material';
import { Button, CircularProgress, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

export const SignInPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email!').required('Required Field!'),
    password: Yup.string().required('Required Field!')
  });

  const fields = [
    {
      name: 'email',
      icon: <Email />,
      label: 'Email'
    },
    {
      name: 'password',
      icon: <Lock />,
      type: 'password',
      label: 'Password'
    }
  ];

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      AuthApi.login(values)
        .then((res) => {
          if (res.user) {
            enqueueSnackbar('You were logged in successfully', { variant: 'success' });
            if (res.accessToken) {
              localStorage.setItem(ACCESS_TOKEN_KEY, res.accessToken);
            }

            navigate(ROUTES.DASHBOARD);
            dispatch(setAccount(res.user));
          }
        })
        .catch((err) => {
          if (err.status === 404) {
            enqueueSnackbar('User not found', { variant: 'warning' });
          } else if (err.status === 424) {
            enqueueSnackbar('Password not matched', { variant: 'warning' });
          } else {
            enqueueSnackbar('Network error', { variant: 'error' });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const handleLogin = () => {
    handleSubmit();
  };

  return (
    <>
      {fields.map(({ name, icon, type = 'text', label }, index) => (
        <TextField
          fullWidth
          key={index}
          name={name}
          type={type}
          label={label}
          value={values[name]}
          disabled={isSubmitting}
          error={Boolean(errors[name] && touched[name])}
          helperText={Boolean(errors[name] && touched[name]) && errors[name]}
          onBlur={handleBlur}
          onChange={handleChange}
          sx={{ mb: 16 }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
          }}
        />
      ))}
      <Button
        fullWidth
        disabled={isSubmitting}
        startIcon={!isSubmitting && <Login />}
        sx={{ mt: 16 }}
        onClick={handleLogin}
      >
        {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Login'}
      </Button>
      {process.env.VISIBLE_GOOGLE_LOGIN && (
        <>
          <Typography
            mt={21}
            color="secondary"
            textAlign="center"
            component={Link}
            to={ROUTES.AUTH.FORGOT_PASSWORD}
            mb={10}
          >
            Forgot your password?
          </Typography>
          <Typography textAlign="center">
            Don't have an account?
            <Typography ml={16} color="secondary" component={Link} to={ROUTES.AUTH.SIGN_UP}>
              Sign Up
            </Typography>
          </Typography>
          <Divider sx={{ my: 10 }}>Or</Divider>
          <Button fullWidth startIcon={<Google />}>
            Continue with Google
          </Button>
        </>
      )}
    </>
  );
};
