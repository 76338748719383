import {
  ChangePasswordPage,
  DashboardPage,
  ForgotPasswordPage,
  MonitorPage,
  ProjectListPage,
  RepositoryListPage,
  SignInPage,
  SignUpPage
} from '../pages';
import { ProfileListPage } from '../pages/Profile';
import { FC } from 'react';

interface IRoute {
  path: string;
  element: FC;
}

export const ROUTES = {
  AUTH: {
    SIGN_IN: '/auth/sign-in',
    SIGN_UP: '/auth/sign-up',
    CONFIRM_EMAIL: '/auth/verify-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CHANGE_PASSWORD: '/auth/change-password'
  },
  DASHBOARD: '/',
  PROJECT: {
    LIST: '/projects'
  },
  REPOSITORY: {
    LIST: '/repositories'
  },
  PROFILE: {
    LIST: '/profiles'
  },
  MONITOR: '/monitor'
};

export const AUTH_ROUTES: IRoute[] = [
  {
    path: ROUTES.AUTH.SIGN_IN,
    element: SignInPage
  },
  {
    path: ROUTES.AUTH.SIGN_UP,
    element: SignUpPage
  },
  {
    path: ROUTES.AUTH.FORGOT_PASSWORD,
    element: ForgotPasswordPage
  },
  {
    path: ROUTES.AUTH.CHANGE_PASSWORD,
    element: ChangePasswordPage
  }
];

export const MAIN_ROUTES: IRoute[] = [
  {
    path: ROUTES.DASHBOARD,
    element: DashboardPage
  },
  {
    path: ROUTES.PROJECT.LIST,
    element: ProjectListPage
  },
  {
    path: ROUTES.REPOSITORY.LIST,
    element: RepositoryListPage
  },
  {
    path: ROUTES.PROFILE.LIST,
    element: ProfileListPage
  },
  {
    path: ROUTES.MONITOR,
    element: MonitorPage
  }
];
