import { ProfileApi } from '../../../apis';
import { ProjectApi } from '../../../apis/project';
import { Modal } from '../../../components';
import { IProfile } from '../../../interfaces';
import * as S from './styles';
import { MenuItem, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';

interface INewProfileModalProps {
  open: boolean;
  profile: IProfile | null;
  onClose: (isReload?: boolean) => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required Field!'),
  email: Yup.string().email('Invalid Email!').required('Required Field!')
});

export const NewProfileModal: FC<INewProfileModalProps> = ({ open, profile, onClose }) => {
  const [projects, setProjects] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      email: '',
      projects: []
    },
    validationSchema,
    onSubmit: (values) => {
      if (profile) {
        ProfileApi.update(profile.id, values)
          .then((res) => {
            enqueueSnackbar(res.msg, { variant: 'success' });
            onClose(true);
          })
          .catch((err) => {
            enqueueSnackbar(err.msg, { variant: 'error' });
          });
      } else {
        ProfileApi.create(values)
          .then((res) => {
            enqueueSnackbar(res.msg, { variant: res.success ? 'success' : 'warning' });
            onClose(true);
          })
          .catch((err) => {
            enqueueSnackbar(err.msg, { variant: 'error' });
          });
      }
    }
  });

  const fetchProjects = () => {
    ProjectApi.readAll()
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleCreate = () => {
    handleSubmit();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (profile) {
      setFieldValue('name', profile.name);
      setFieldValue('email', profile.email);
      setFieldValue('projects', profile.projects);
    }
  }, [profile]);

  return (
    <Modal
      title={profile ? 'Edit Profile' : 'New Profile'}
      okText={profile ? 'Submit' : 'Create'}
      onOk={handleCreate}
      onCancel={onClose}
      open={open}
      onClose={handleClose}
    >
      <S.Content>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>
              <TextField
                fullWidth
                name="name"
                value={values.name}
                error={Boolean(errors.name && touched.name)}
                helperText={Boolean(errors.name && touched.name) && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              <TextField
                fullWidth
                name="email"
                value={values.email}
                error={Boolean(errors.email && touched.email)}
                helperText={Boolean(errors.email && touched.email) && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Projects</TableCell>
            <TableCell>
              <TextField
                select
                fullWidth
                name="projects"
                value={values.projects}
                SelectProps={{ multiple: true }}
                error={Boolean(errors.projects && touched.projects)}
                helperText={Boolean(errors.projects && touched.projects) && errors.projects}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {projects.map(({ id, name }, index) => (
                  <MenuItem value={id} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>
          </TableRow>
        </TableBody>
      </S.Content>
    </Modal>
  );
};
