import { ProjectApi } from '../../../apis/project';
import { RepositoryAPI } from '../../../apis/repository';
import { Modal } from '../../../components';
import * as S from './styles';
import { RemoveCircle, Add } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  TableBody,
  TableCell,
  Chip,
  TableRow,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { pick } from '../../../utils/pick';
import { useSnackbar } from 'notistack';
import { IProject } from '../../../interfaces';
import { getHostByType } from '../../../utils/getHostByType';

interface INewRepositoryModalProps {
  selectedRepository?: string | null;
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
}

export interface IRepository {
  name: string;
  project: string;
  origins: {
    name: string;
    url: string;
  }[];
  branches: string[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required Field!'),
  project: Yup.string().required('Required Field!'),
  origins: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Required Field!'),
      url: Yup.string().required('Required Field!')
    })
  )
});

export const RepositoryModal: FC<INewRepositoryModalProps> = ({ open, selectedRepository, onClose, onRefresh }) => {
  const [branchEdit, setBranchEdit] = useState(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const { values, setValues, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik<IRepository>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      project: '',
      origins: [{ name: 'origin', url: '' }],
      branches: []
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (selectedRepository) {
        await RepositoryAPI.edit(
          selectedRepository,
          pick({
            ...values,
            origins: values.origins.map((origin) => pick(origin, ['id', 'name', 'url']))
          }, ['name', 'project', 'origins', 'branches'])
        ).then(() => {
          onRefresh();
          onClose();
        });
      } else {
        await RepositoryAPI.create(values).then((res) => {
          enqueueSnackbar(res.msg, { variant: res.success ? 'success' : 'warning' });
          onRefresh();
          onClose();
        }).catch((res) => {
          enqueueSnackbar(res.msg, { variant: 'error' });
        });
      }
    }
  });

  useEffect(() => {
    ProjectApi.readAll().then((res) => {
      setProjects(res.data);
    });
  }, []);

  useEffect(() => {
    if (selectedRepository) {
      RepositoryAPI.getById(selectedRepository).then((res) => {
        setValues({ ...res.data, project: res.data.project?.id || '' });
      });
    }
  }, [selectedRepository]);

  const handleSave = () => {
    handleSubmit();
  };

  const handleAddOrigin = () => {
    setFieldValue(
      'origins',
      values.origins.concat({
        name: '',
        url: ''
      })
    );
  };

  const handleRemoveOrigin = (index: number) => {
    const rows = [...values.origins];
    rows.splice(index, 1);
    setFieldValue('origins', rows);
  };

  const handleAddBranch = () => {
    setBranchEdit(true);
    setFieldValue('branches', values.branches.concat(''));
  };

  const handleDeleteBranch = (index: number) => {
    const branches = [...values.branches];
    branches.splice(index, 1);
    setFieldValue('branches', branches);
  };

  const handleBlurBranch = (branch: string) => {
    if (branch) {
      setBranchEdit(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      setBranchEdit(false);
    }
  };

  const handleSelectProject = (e) => {
    const projectId = e.target.value;
    const selectedProject = projects.find((project) => project.id === projectId);
    if (selectedProject) {
      const host =  getHostByType(selectedProject.type)?.host;
      const profileName = selectedProject?.profile?.name;
      const hostName = `${profileName ? (profileName?.replace(/\s/g, '').toLowerCase() + '.') : ''}${host}`;
      setFieldValue('origins', values.origins.map((origin, index) => index === 0 ? {
        ...origin,
        url: `git@${hostName}:`,
      } : origin));
      handleChange(e);
    }
  };

  return (
    <Modal title="New Repository" okText="Save" onOk={handleSave} onCancel={onClose} open={open} onClose={onClose}>
      <S.Content>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>
              <TextField
                fullWidth
                name="name"
                value={values.name}
                error={Boolean(errors.name && touched.name)}
                helperText={Boolean(errors.name && touched.name) && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="project"
                sx={{ minWidth: 100 }}
                value={values.project}
                onChange={handleSelectProject}
              >
                {projects &&
                  projects.map((project, index) => (
                    <MenuItem key={index} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'baseline' }}>Origins</TableCell>
            <TableCell>
              <Grid container alignItems="center" spacing={8} mb={4}>
                {values.origins.map((origin, index) => (
                  <React.Fragment key={index}>
                    <Grid item lg={4} display="flex" alignItems="center">
                      <Box>
                        <TextField
                          disabled={index === 0}
                          fullWidth
                          name={`origins[${index}].name`}
                          value={origin.name}
                          error={Boolean(
                            errors?.origins &&
                              errors?.origins[index] &&
                              errors?.origins[index]['name'] &&
                              touched.origins &&
                              touched.origins[index] &&
                              touched.origins[index]['name']
                          )}
                          helperText={
                            errors?.origins &&
                            errors?.origins[index] &&
                            errors?.origins[index]['name'] &&
                            touched.origins &&
                            touched.origins[index] &&
                            touched.origins[index].name &&
                            errors?.origins[index]['name']
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={7}>
                      <TextField
                        fullWidth
                        name={`origins[${index}].url`}
                        value={origin.url}
                        error={Boolean(
                          errors?.origins &&
                            errors?.origins[index] &&
                            errors?.origins[index]['url'] &&
                            touched.origins &&
                            touched.origins[index] &&
                            touched.origins[index].url
                        )}
                        helperText={
                          errors?.origins &&
                          errors?.origins[index] &&
                          errors?.origins[index]['url'] &&
                          touched.origins &&
                          touched.origins[index] &&
                          touched.origins[index].url &&
                          errors.origins[index]['url']
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={1}>
                      {index !== 0 && (
                        <IconButton onClick={() => handleRemoveOrigin(index)}>
                          <RemoveCircle />
                        </IconButton>
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
              <IconButton onClick={handleAddOrigin}>
                <Add />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'baseline' }}>Branches</TableCell>
            <TableCell>
              <S.BranchPaper variant="outlined">
                {values.branches.map((branch, index, _array) => {
                  return index < _array.length - 1 || !branchEdit ? (
                    <ListItem key={index}>
                      <Chip label={branch} onDelete={() => handleDeleteBranch(index)} />
                    </ListItem>
                  ) : (
                    <ListItem key={index}>
                      <TextField
                        size="small"
                        sx={{ width: 100 }}
                        variant="standard"
                        name={`branches[${index}]`}
                        value={branch}
                        onBlur={() => handleBlurBranch(branch)}
                        onChange={handleChange}
                        onKeyDown={handleEnter}
                      />
                    </ListItem>
                  );
                })}
              </S.BranchPaper>
              <IconButton onClick={handleAddBranch}>
                <Add />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </S.Content>
    </Modal>
  );
};
