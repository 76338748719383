import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Layout = styled(PerfectScrollbar)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(40, 20)};

  a {
    text-decoration: none;
  }
`;

export const Logo = styled(Link)`
  display: block;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing(30)};

  svg {
    height: 70px;
  }
`;

export const Content = styled(Box)`
  max-width: 380px;
  width: 100%;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.shadows[2]};
  padding: ${(props) => props.theme.spacing(40, 24, 30)};
  display: flex;
  flex-direction: column;
`;
