import { ENDPOINTS } from '../constants';
import { Http } from '../utils';

const register = (data): Promise<any> => {
  return Http.post(ENDPOINTS.AUTH.REGISTER, data);
};

const login = (data): Promise<any> => {
  return Http.post(ENDPOINTS.AUTH.LOGIN, data);
};

export const me = (): Promise<any> => {
  return Http.get(ENDPOINTS.AUTH.ME);
};

export const AuthApi = {
  register,
  login,
  me
};
