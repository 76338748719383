import { SettingApi } from '../../../apis';
import { Modal } from '../../../components';
import { ACCESS_TOKEN_KEY } from '../../../constants';
import { ISetting } from '../../../interfaces';
import { setAccount } from '../../../store/actions';
import { storage } from '../../../utils';
import * as S from './style';
import { PowerSettingsNew, RestartAlt, SettingsOutlined } from '@mui/icons-material';
import { IconButton, Stack, Switch, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  rootPath: Yup.string().required('Required Field!'),
  sshConfigPath: Yup.string().required('Required Field!'),
  interval: Yup.number().required('Required Field!'),
  isSyncing: Yup.boolean()
});

const Header: FC = () => {
  const [setting, setSetting] = useState<ISetting>();
  const [visibleSettingModal, setVisibleSettingModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { values, errors, touched, resetForm, setFieldValue, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      rootPath: '',
      sshConfigPath: '',
      interval: 0,
      isSyncing: false
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      if (setting) {
        SettingApi.update(values)
          .then((res) => {
            console.log(res);
            enqueueSnackbar(res.msg, { variant: 'success' });
            setVisibleSettingModal(false);
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(err.msg, { variant: 'error' });
          });
      } else {
        SettingApi.create(values)
          .then((res) => {
            console.log(res);
            enqueueSnackbar(res.msg, { variant: 'success' });
            setVisibleSettingModal(false);
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(err.msg, { variant: 'error' });
          });
      }
    }
  });

  const fetchSetting = () => {
    SettingApi.read()
      .then((res) => {
        const setting = res.data;
        setSetting(setting);
        setFieldValue('interval', setting.interval);
        setFieldValue('isSyncing', setting.isSyncing);
        setFieldValue('rootPath', setting.rootPath);
        setFieldValue('sshConfigPath', setting.sshConfigPath);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = () => {
    storage.removeItem(ACCESS_TOKEN_KEY);
    dispatch(setAccount(null));
  };

  const handleClose = () => {
    setVisibleSettingModal(false);
    resetForm();
  };

  const handleOpen = () => {
    setVisibleSettingModal(true);
  };

  const handleRestart = () => {
    console.log('Restart handler');
  };

  const handleSave = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (visibleSettingModal) {
      fetchSetting();
    }
  }, [visibleSettingModal]);

  return (
    <>
      <S.Header>
        <Stack direction="row" spacing={8}>
          <IconButton onClick={handleOpen}>
            <SettingsOutlined />
          </IconButton>
          <IconButton onClick={handleLogout}>
            <PowerSettingsNew />
          </IconButton>
        </Stack>
      </S.Header>
      {visibleSettingModal && (
        <Modal
          title="Settings"
          open={visibleSettingModal}
          onClose={handleClose}
          okText="Save"
          onOk={handleSave}
          onCancel={handleClose}
        >
          <S.Settings>
            <TableBody>
              <TableRow>
                <TableCell>Root Path</TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    name="rootPath"
                    value={values.rootPath}
                    error={Boolean(errors.rootPath && touched.rootPath)}
                    helperText={Boolean(errors.rootPath && touched.rootPath) && errors.rootPath}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>SSH Config Path</TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    name="sshConfigPath"
                    value={values.sshConfigPath}
                    error={Boolean(errors.sshConfigPath && touched.sshConfigPath)}
                    helperText={Boolean(errors.sshConfigPath && touched.sshConfigPath) && errors.sshConfigPath}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Interval</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    name="interval"
                    value={values.interval}
                    error={Boolean(errors.interval && touched.interval)}
                    helperText={Boolean(errors.interval && touched.interval) && errors.interval}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ maxWidth: 80, mr: 10 }}
                  />{' '}
                  <Typography sx={{ display: 'inline-block', mt: 10 }}>minutes</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Sync</TableCell>
                <TableCell>
                  <Switch name="isSyncing" value={values.isSyncing} onChange={handleChange} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Restart</TableCell>
                <TableCell>
                  <IconButton onClick={handleRestart}>
                    <RestartAlt />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </S.Settings>
        </Modal>
      )}
    </>
  );
};

export default Header;
