import { Check, Lock } from '@mui/icons-material';
import { Button, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

export const ChangePasswordPage: FC = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Required Field!')
  });

  const fields = [
    {
      name: 'password',
      icon: <Lock />,
      label: 'Choose Password'
    },
    {
      name: 'confirmPassword',
      icon: <Lock />,
      label: 'Repeat Password'
    }
  ];

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    onSubmit: (values) => {
      console.log(values);
      setSubmitting(false);
    }
  });

  const handleSend = () => {
    handleSubmit();
  };

  return (
    <>
      {fields.map(({ name, icon, label }, index) => (
        <TextField
          fullWidth
          key={index}
          name={name}
          type="password"
          label={label}
          value={values[name]}
          disabled={isSubmitting}
          error={Boolean(errors[name] && touched[name])}
          helperText={Boolean(errors[name] && touched[name]) && errors[name]}
          onBlur={handleBlur}
          onChange={handleChange}
          sx={{ mb: 32 }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
          }}
        />
      ))}
      <Button sx={{ mb: 16 }} startIcon={!isSubmitting && <Check />} onClick={handleSend} disabled={isSubmitting}>
        {isSubmitting ? <CircularProgress size={20} /> : 'Save'}
      </Button>
    </>
  );
};
