import { Box, CircularProgress } from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { AuthApi } from '../apis';
import { AUTH_ROUTES, MAIN_ROUTES, ROUTES } from '../constants';
import { AuthLayout, MainLayout } from '../layouts';
import { setAccount } from '../store/actions';
import { getAccount } from '../store/selectors';

const AppRouter: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  const account = useSelector(getAccount);

  if (!account) {
    AuthApi.me()
      .then((res) => {
        if (res.user) {
          dispatch(setAccount(res.user));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  return isLoading ? (
    <Box flex={1} height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress color="secondary" />
    </Box>
  ) : (
    <Router>
      {!account ? (
        <Routes>
          {AUTH_ROUTES.map(({ path, element: C }) => (
            <Route
              path={path}
              key={path}
              element={
                <AuthLayout>
                  <C />
                </AuthLayout>
              }
            />
          ))}
          <Route path="*" element={<Navigate to={ROUTES.AUTH.SIGN_IN} />} />
        </Routes>
      ) : (
        <Routes>
          {MAIN_ROUTES.map(({ path, element: C }) => (
            <Route
              path={path}
              key={path}
              element={
                <MainLayout>
                  <C />
                </MainLayout>
              }
            />
          ))}
          <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRouter;
