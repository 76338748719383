import { ProfileApi } from '../../../apis';
import { ProjectApi } from '../../../apis/project';
import { Modal } from '../../../components';
import { IProject } from '../../../interfaces';
import * as S from './styles';
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { Hosts } from '../../../constants';

interface INewProjectModalProps {
  open: boolean;
  project?: IProject | null;
  onClose: (isReload?: boolean) => void;
}

export const ProjectFormModal: FC<INewProjectModalProps> = ({ open, project, onClose }) => {
  const [profiles, setProfiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { values, errors, touched, setFieldValue, setValues, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      type: 'github',
      profile: '',
      status: false
    },
    onSubmit: (values) => {
      if (project) {
        ProjectApi.update(project.id, values)
          .then((res) => {
            enqueueSnackbar(res.msg, { variant: 'success' });
          })
          .catch((err) => {
            enqueueSnackbar(err.msg, { variant: 'error' });
          })
          .finally(() => {
            onClose(true);
          });
      } else {
        ProjectApi.create(values)
          .then((res) => {
            enqueueSnackbar(res.msg, { variant: 'success' });
          })
          .catch((err) => {
            enqueueSnackbar(err.msg, { variant: 'error' });
          })
          .finally(() => {
            onClose(true);
          });
      }
    }
  });

  const handleCreate = () => {
    handleSubmit();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    ProfileApi.readAll()
      .then((res) => {
        setProfiles(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (project) {
      setFieldValue('name', project.name);
      setFieldValue('type', project.type);
      if (project.profile) {
        setFieldValue('profile', project.profile.id);
      }
    } else {
      setValues({
        name: '',
        type: 'github',
        profile: '',
        status: false
      });
    }
  }, [project]);

  return (
    <Modal
      title={`${project ? 'Edit' : 'New'} Project`}
      okText={project ? 'Edit' : 'Create'}
      onOk={handleCreate}
      open={open}
      onClose={handleClose}
    >
      <S.Content>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>
              <TextField
                fullWidth
                name="name"
                value={values.name}
                error={Boolean(errors.name && touched.name)}
                helperText={Boolean(errors.name && touched.name) && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>
              <RadioGroup value={values.type} name="type" onChange={handleChange}>
                {Hosts.map((host, index) => (
                  <FormControlLabel key={index} value={host.type} control={<Radio />} label={host.label} />
                ))}
              </RadioGroup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Profile</TableCell>
            <TableCell>
              <TextField
                fullWidth
                select
                name="profile"
                value={values.profile}
                error={Boolean(errors.profile && touched.profile)}
                helperText={Boolean(errors.profile && touched.profile) && errors.profile}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {profiles.map(({ id, name }, index) => (
                  <MenuItem value={id} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <Switch value={values.status} />
            </TableCell>
          </TableRow>
        </TableBody>
      </S.Content>
    </Modal>
  );
};
