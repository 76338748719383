import { ProjectApi } from '../../../apis/project';
import { List } from '../../../components';
import { PAGE_LIMIT } from '../../../constants';
import { IProject, Order } from '../../../interfaces';
import { ProjectFormModal } from '../Form';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Chip, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';

export const ProjectListPage: FC = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [visibleFormModal, setVisibleFormModal] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [order, setOrder] = useState<Order>(Order.Desc);
  const [orderBy, setOrderBy] = useState<string>('name');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<IProject | null>();

  const { enqueueSnackbar } = useSnackbar();

  const fetchProjects = () => {
    setIsLoading(true);
    ProjectApi.readAll({
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT,
        sort: {
          [orderBy]: order
        },
        populate: 'profile'
      }
    })
      .then((res) => {
        setProjects(res.data);
        setTotalPage(res.pagination.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    setVisibleFormModal(true);
    setSelectedProject(null);
  };

  const handleClose = (isReload?: boolean) => {
    if (isReload) {
      fetchProjects();
    }
    setSelectedProject(null);
    setVisibleFormModal(false);
  };

  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  const handleChangeType = (id, type) => {
    ProjectApi.update(id, { type })
      .then((res) => {
        fetchProjects();
        enqueueSnackbar(res.msg, { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.msg, { variant: 'error' });
      });
  };

  const columns = [
    {
      title: 'Name',
      render: (row) => row.name
    },
    {
      title: 'Type',
      render: (row) => (
        <Select value={row.type} onChange={(e) => handleChangeType(row.id, e.target.value)}>
          <MenuItem value="github">Github</MenuItem>
          <MenuItem value="bitbucket">Bitbucket</MenuItem>
          <MenuItem value="gitlab">Gitlab</MenuItem>
          <MenuItem value="azure">Azure DevOps</MenuItem>
        </Select>
      )
    },
    {
      title: 'Profile',
      render: (row) => (
        <Stack direction="row" spacing={8}>
          <AccountCircleOutlined />
          <Typography>{row.profile?.name}</Typography>
        </Stack>
      )
    },
    {
      title: 'Repositories',
      render: (row) => (
        <Stack direction="row" spacing={4}>
          {row.repositories?.map(({ name }, index) => (
            <Chip key={index} label={name} />
          ))}
        </Stack>
      )
    }
  ];

  const handleDelete = (id: string) => {
    ProjectApi.remove(id)
      .then((res) => {
        fetchProjects();
        enqueueSnackbar(res.msg, { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.msg, { variant: 'error' });
      });
  };

  const handleEdit = (id: string) => {
    const project = projects.find(({ id: projectId }) => projectId === id);
    if (project) {
      setSelectedProject(project);
      setVisibleFormModal(true);
    }
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? Order.Desc : Order.Asc);
    setOrderBy(property);
  };

  useEffect(() => {
    fetchProjects();
  }, [pageNumber, order, orderBy]);

  return (
    <>
      <List
        data={projects}
        columns={columns}
        isLoading={isLoading}
        totalPage={totalPage}
        pageNumber={pageNumber}
        onNew={handleOpen}
        onEdit={handleEdit}
        onSort={handleSort}
        onDelete={handleDelete}
        onPageChange={handlePageChange}
      />
      {visibleFormModal && <ProjectFormModal project={selectedProject } open={visibleFormModal} onClose={handleClose} />}
    </>
  );
};
