import { AuthApi } from '../../../apis';
import { ROUTES } from '../../../constants';
import { AccountBox, Add, Email, Lock } from '@mui/icons-material';
import { Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

export const SignUpPage: FC = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required Field!'),
    lastName: Yup.string().required('Required Field!'),
    email: Yup.string().email('Invalid Email').required('Required Field!'),
    password: Yup.string().required('Required Field!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Confirm password does not matched!')
      .required('Required Field!')
  });

  const fields = [
    {
      name: 'firstName',
      icon: <AccountBox />,
      label: 'First Name'
    },
    {
      name: 'lastName',
      icon: <AccountBox />,
      label: 'Last Name'
    },
    {
      name: 'email',
      icon: <Email />,
      label: 'Email'
    },
    {
      name: 'password',
      icon: <Lock />,
      type: 'password',
      label: 'Password'
    },
    {
      name: 'confirmPassword',
      icon: <Lock />,
      type: 'password',
      label: 'Repeat Password'
    }
  ];

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    onSubmit: (values) => {
      const { confirmPassword, ...credentials } = values;
      AuthApi.register(credentials)
        .then(() => {
          navigate(ROUTES.AUTH.SIGN_IN);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const handleLogin = () => {
    handleSubmit();
  };

  return (
    <>
      {fields.map(({ name, icon, type = 'text', label }, index) => (
        <TextField
          fullWidth
          key={index}
          name={name}
          type={type}
          label={label}
          value={values[name]}
          disabled={isSubmitting}
          error={Boolean(errors[name] && touched[name])}
          helperText={Boolean(errors[name] && touched[name]) && errors[name]}
          onBlur={handleBlur}
          onChange={handleChange}
          sx={{ mb: 16 }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
          }}
        />
      ))}
      <Button fullWidth startIcon={!isSubmitting && <Add />} sx={{ mt: 16 }} onClick={handleLogin}>
        {isSubmitting ? <CircularProgress /> : 'Create Account'}
      </Button>
      <Typography mt={27} color="text.secondary" textAlign="center">
        Already have an account?{' '}
        <Typography ml={16} color="secondary" component={Link} to={ROUTES.AUTH.SIGN_IN}>
          Login
        </Typography>
      </Typography>
    </>
  );
};
