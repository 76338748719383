import { ENDPOINTS } from '../constants';
import { IGetParams } from '../interfaces';
import { Http } from '../utils';

const read = (id): Promise<any> => {
  return Http.get(ENDPOINTS.PROFILE.ID.replace(':id', id));
};

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.PROFILE.BASE, params);
};

const create = (profile): Promise<any> => {
  return Http.post(ENDPOINTS.PROFILE.BASE, profile);
};

const update = (id, data): Promise<any> => {
  return Http.put(ENDPOINTS.PROFILE.ID.replace(':id', id), data);
};

const remove = (id): Promise<any> => {
  return Http.delete(ENDPOINTS.PROFILE.ID.replace(':id', id));
};

export const ProfileApi = {
  read,
  readAll,
  create,
  update,
  remove
};
